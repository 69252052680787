import React from 'react'
import { ExportButton, Page, Text, Table } from '@fielded/shared-ui'

import ExportImportCard from '../../../../../van-shared/components/ExportImportCard'

const PSMInboundShipmentsOverviewDownload = ({
  isDownloading,
  onDownloadTemplate
}) => {
  const columns = [
    {
      key: 'date',
      header: 'date',
      dataKey: 'date',
      disableSort: true
    },
    {
      key: 'destinationWarehouseCode',
      header: 'destinationWarehouseCode',
      dataKey: 'destinationWarehouseCode',
      disableSort: true
    },
    {
      key: 'productId',
      header: 'productId',
      dataKey: 'productId',
      disableSort: true
    },
    {
      key: 'batchNo',
      header: 'batchNo',
      dataKey: 'batchNo',
      disableSort: true
    },
    {
      key: 'manufacturer',
      header: 'manufacturer',
      dataKey: 'manufacturer',
      disableSort: true
    },
    {
      key: 'expiry',
      header: 'expiry',
      dataKey: 'expiry',
      disableSort: true
    },
    {
      key: 'manufacturer',
      header: 'manufacturer',
      dataKey: 'manufacturer',
      disableSort: true
    },
    {
      key: 'expiry',
      header: 'expiry',
      dataKey: 'expiry',
      disableSort: true
    },
    {
      key: 'quantity',
      header: 'quantity',
      dataKey: 'quantity',
      disableSort: true
    },
    {
      key: 'programId',
      header: 'programId',
      dataKey: 'programId',
      disableSort: true
    },
    {
      key: 'funderId',
      header: 'funderId',
      dataKey: 'funderId',
      disableSort: true
    },
    {
      key: 'productCodeAlias',
      header: 'productCodeAlias',
      dataKey: 'productCodeAlias',
      disableSort: true
    }
  ]

  const rows = [
    {
      date: '3/11/2025',
      destinationWarehouseCode: 'KE-EPN-CHAK',
      productId: 'product:EPN-AMO001',
      batchNo: '88087',
      manufacturer: 'unknown',
      expiry: '1/11/2029',
      quantity: '8',
      programId: 'program:ke-epn',
      funderId: 'funder:ke-epn',
      productCodeAlias: 'epn'
    },
    {
      date: '3/11/2025',
      destinationWarehouseCode: 'KE-EPN-CHAK',
      productId: 'product:EPN-AMO002',
      batchNo: '88017',
      manufacturer: 'unknown',
      expiry: '1/01/2028',
      quantity: '100',
      programId: 'program:ke-epn',
      funderId: 'funder:ke-epn',
      productCodeAlias: 'epn'
    },
    {
      date: '3/11/2025',
      destinationWarehouseCode: 'KE-EPN-CHAK',
      productId: 'product:EPN-AMO003',
      batchNo: '88000',
      manufacturer: 'unknown',
      expiry: '1/01/2029',
      quantity: '10',
      programId: 'program:ke-epn',
      funderId: 'funder:ke-epn',
      productCodeAlias: 'epn'
    }
  ]

  return (
    <Page.Panel>
      <ExportImportCard
        title='Download shipment template'
        description='Click on the button below to download the Excel template for your shipment import. Ensure you do not modify the structure of the template to avoid upload errors.'
        button={
          <ExportButton
            buttonText='Download template'
            loading={isDownloading}
            filename={`shipments-${new Date().toJSON()}.xlsx`}
            onExport={onDownloadTemplate}
          />
        }
      >

        <Text>Here is an example how the Excel file should look:</Text>
        <div className='inbound-shipments-upload-example__wrapper'>
          <Table
            entries={rows}
            columns={columns}
            headerTextStyle='lowercase'
          />
        </div>
      </ExportImportCard>
    </Page.Panel>
  )
}

export default PSMInboundShipmentsOverviewDownload
