import React from 'react'
import { Link } from 'react-router-dom'

import { Page, SubNavigation } from '@fielded/shared-ui'
import { Truck } from '@fielded/shared-ui/src/icons'

import { VIEW_TYPES } from './PSMInboundShipmentsOverviewContainer'
import PSMInboundShipmentsOverviewDownload from './PSMInboundShipmentsOverviewDownload'
import PSMInboundShipmentsOverviewUpload from './PSMInboundShipmentsOverviewUpload'

const PSMInboundShipmentsOverview = ({
  config,
  view,
  history,
  subNavigationItems,
  documentFormRef,
  isDownloading,
  isUploading,
  onDownloadTemplate,
  onUploadTemplate
}) => {
  return (
    <Page>
      <Page.HeaderNew
        title='Shipments'
        subtitle='Import shipment'
        icon={<Truck />}
        history={history}
        showOfflineWarning
      />

      <Page.Panel narrow alignLeft>
        <aside>
          <SubNavigation
            className='last-mile-deliveries__sub-nav vs-u-align-end'
            label=''
            items={subNavigationItems}
            linkRenderer={({ key, to, className, children }) => (
              <Link
                key={key}
                to={to}
                className={className}
              >
                {children}
              </Link>
            )}
          />
        </aside>
      </Page.Panel>

      {!view || view === VIEW_TYPES.DOWNLOAD ? (
        <PSMInboundShipmentsOverviewDownload
          isDownloading={isDownloading}
          onDownloadTemplate={onDownloadTemplate}
        />
      ) : (
        <Page.Panel narrow alignLeft>
          <PSMInboundShipmentsOverviewUpload
            documentFormRef={documentFormRef}
            isUploading={isUploading}
            onUploadTemplate={onUploadTemplate}
          />
        </Page.Panel>
      )}
    </Page>
  )
}

export default PSMInboundShipmentsOverview
